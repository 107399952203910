import "./App.css";

import * as Yup from "yup"

import { Badge, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Fab, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import React, { useEffect, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Alert from '@mui/material/Alert';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LoadingButton from '@mui/lab/LoadingButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import moment from 'moment';

function App() {
    const [products, setProducts] = useState()
    const [minOrderPrice, setMinOrderPrice] = useState()
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [tabValue, setTabValue] = useState('valgauSavo');
    const [productsDownloaded, setProductsDownloaded] = useState(false)
    useEffect(() => {
        if (tabValue === "order" && !productsDownloaded) {
            axios.get("https://valgausavo-db098e5ac2e3.herokuapp.com/products").then((resp) => {
                setProducts(resp.data.products)
                setMinOrderPrice(resp.data.minOrderPrice)
                setProductsDownloaded(true)
            }).catch((err) => {
                console.log("errr", err)
            })
        }

    }, [tabValue, productsDownloaded]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const ReduceQuantityFab = ({ values, product, productCartIndex, arrayHelpers }) => {
        // console.log("product", product)

        return (
            <Fab size="small" color={values.products[productCartIndex].quantity !== product.min ? "success" : "disabled"} aria-label="reduce"
                onClick={async () => {
                    if (values.products[productCartIndex].quantity !== product.min) {
                        arrayHelpers.remove(productCartIndex)
                        arrayHelpers.insert(productCartIndex, {
                            ...values.products[productCartIndex],
                            quantity: values.products[productCartIndex].quantity - product.min,
                            price: product.unit === "g"  ? (product.price * ((values.products[productCartIndex].quantity - product.min) / 1000)).toFixed(2) : ((product.price * (product.averageWeight/(product.averageWeightUnit === "g" ? 1000 : 1)).toFixed(2)) * (values.products[productCartIndex].quantity - product.min)).toFixed(2),

                            ...(values.products[productCartIndex].unit === "vnt" && values.products[productCartIndex].averageWeight !== undefined && {averageWeight: product.averageWeightUnit === "g" ? values.products[productCartIndex].averageWeightUnit === "kg" ? values.products[productCartIndex].averageWeight-(product.averageWeight/1000) < 1 ? Math.round((values.products[productCartIndex].averageWeight-(product.averageWeight/1000))*1000) : Math.round((values.products[productCartIndex].averageWeight-(Math.round((product.averageWeight/1000) *100 )/100)) *100)/100 : values.products[productCartIndex].averageWeight - product.averageWeight : values.products[productCartIndex].averageWeight - product.averageWeight}),
                            
                            ...(values.products[productCartIndex].unit === "vnt" && values.products[productCartIndex].averageWeight !== undefined && {averageWeightUnit: values.products[productCartIndex].averageWeightUnit === "kg" ? values.products[productCartIndex].averageWeight - (product.averageWeight/1000) > 0.999 ? "kg" : "g" : "g"}) 
                        })
                    }
                }}
            >
                <RemoveIcon />
            </Fab>
        )
    }

    const IncreaseQuantityFab = ({ values, product, productCartIndex, arrayHelpers }) => {

        return (
            <Fab size="small" color={(values.products[productCartIndex].quantity < product.max) ? "success" : "seconadry"} aria-label="increase" className="ms-0"
                onClick={async () => {
                    if (values.products[productCartIndex].quantity < product.max) {
                        arrayHelpers.remove(productCartIndex)
                        arrayHelpers.insert(productCartIndex, {
                            ...values.products[productCartIndex],
                            quantity: values.products[productCartIndex].quantity + product.min,
                            price: product.unit === "g" ? (product.price * ((values.products[productCartIndex].quantity + product.min) / 1000)).toFixed(2) : ((product.price * (product.averageWeight/(product.averageWeightUnit === "g" ? 1000 : 1)).toFixed(2)) * (values.products[productCartIndex].quantity + product.min)).toFixed(2),

                            ...(values.products[productCartIndex].unit === "vnt" && values.products[productCartIndex].averageWeight !== undefined && {averageWeight: product.averageWeightUnit === "g" ? values.products[productCartIndex].averageWeightUnit === "g" ? values.products[productCartIndex].averageWeight+product.averageWeight > 999 ? ((values.products[productCartIndex].averageWeight+product.averageWeight)/1000) : values.products[productCartIndex].averageWeight+product.averageWeight : values.products[productCartIndex].averageWeight + (product.averageWeight/1000) : values.products[productCartIndex].averageWeight + product.averageWeight}),

                            ...(values.products[productCartIndex].unit === "vnt" && values.products[productCartIndex].averageWeight !== undefined && {averageWeightUnit: values.products[productCartIndex].averageWeightUnit === "g" ? values.products[productCartIndex].averageWeight+product.averageWeight > 999 ? "kg" : "g" : "kg"}) 
                        })
                    } else {
                        setOpenSnackBar(true)
                    }

                }}
            >
                <AddIcon />
            </Fab>
        )
    }

    const QuantityChip = ({ product, values, productCartIndex}) => {
        return (
            <Chip className="mx-2" label={`
                ${product.perUnit === "kg" ? 
                    values.products[productCartIndex].quantity > 999 ? 
                    values.products[productCartIndex].quantity / 1000 
                    : values.products[productCartIndex].quantity 
                    : values.products[productCartIndex].quantity} ${product.perUnit === "kg" ? 
                        values.products[productCartIndex].quantity > 999 ? "kg" 
                        : values.products[productCartIndex].unit : values.products[productCartIndex].unit} ${values.products[productCartIndex].unit === "vnt" ? `(~${values.products[productCartIndex].averageWeight}${values.products[productCartIndex].averageWeightUnit})` : ""}`} variant="outlined" 
                        sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal',
                            },
                          }} />
        )
    }

    const ProductCard = ({ product, arrayHelpers, index, values }) => {

        const productCartIndex = values.products.findIndex((p) => p.id === product.id)

        return (
            <div className="col-lg-4 col-md-6 col-sm-12 p-2">

                < Card className={`${productCartIndex !== -1 ? "border border-success-subtle" : ""}`}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={`/assets/images/${product.productImage}`}
                        alt={product.title}
                    />
                    <CardContent className="pb-2">
                        <Typography gutterBottom component="div" className="text-center">
                            {product.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className="mb-2">
                            {product.productText}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className="fst-italic text-body-tertiary">
                            Ūkis: {product.farm}
                        </Typography>
                    </CardContent>
                    {productCartIndex !== -1 && (
                        <div className="px-2"><Chip label="Produktas krepšelyje!" size="small" variant="outlined" color="success" className="text-center w-100" /></div>
                    )}
                    {productCartIndex === -1 ? (
                        <>
                            <CardActions className="justify-content-center">
                                <Typography color="text.secondary">
                                    Kaina: {product.price.toFixed(2)} € / {product.perUnit}
                                </Typography>
                            </CardActions>
                            <CardActions className="justify-content-center">
                                <Button
                                    className="w-100"
                                    variant="outlined"
                                    color="success"
                                    onClick={async () => {
                                        arrayHelpers.insert(index, {
                                            id: product.id,
                                            title: product.title,
                                            quantity: product.min,
                                            unit: product.unit,
                                            farm: product.farm,
                                            price: product.unit === "g" ? (product.price * (product.min / 1000)).toFixed(2) : `${(product.price * product.averageWeight/(product.averageWeightUnit === "g" ? 1000 : 1)* product.min).toFixed(2)}`,
                                            priceKg: product.price,
                                            ...(product.averageWeight !== null && {averageWeight: product.averageWeight}),
                                            ...(product.averageWeightUnit !== null && {averageWeightUnit: product.averageWeightUnit})
                                        })
                                    }
                                    }
                                >
                                    Į Krepšelį
                                    <AddShoppingCart className="ms-3" style={{ fontSize: "20px" }} />
                                </Button>
                            </CardActions>
                        </>
                    ) :
                        (
                            <>

                                <CardActions className="justify-content-around">
                                    <Typography color="text.secondary">
                                        Kaina:{product.unit === "vnt" ? (<span className='text-info'>*</span>) : ""} {values.products[productCartIndex].price} €
                                    </Typography>
                                    <Typography color="text.secondary" className="m-0">
                                        /
                                    </Typography>
                                    <div className="m-0"> 
                                        <ReduceQuantityFab
                                            values={values}
                                            product={product}
                                            productCartIndex={productCartIndex}
                                            arrayHelpers={arrayHelpers}
                                        />
                                        <QuantityChip
                                            product={product}
                                            values={values}
                                            productCartIndex={productCartIndex}
                                        />
                                        <IncreaseQuantityFab
                                            values={values}
                                            product={product}
                                            productCartIndex={productCartIndex}
                                            arrayHelpers={arrayHelpers}
                                        />

                                    </div>
                                </CardActions>

                                <CardActions className="justify-content-center">
                                    <Button
                                        size="small"
                                        className="w-100"
                                        variant="outlined"
                                        color="warning"
                                        onClick={() =>
                                            arrayHelpers.remove(productCartIndex)
                                        }
                                    >
                                        Išimti iš krepšelio
                                        <RemoveShoppingCartIcon className="ms-3" style={{ fontSize: "20px" }} />
                                    </Button>
                                </CardActions>
                            </>

                        )}
                </Card>

            </div>
        )
    }

    const calculateNextMonday = () => {
        const dayIneed = 1; // for Monday
        const today = moment().isoWeekday();

        // if we haven't yet passed the day of the week that I need:
        if (today !== 7 && today !== 6 && today !== 5) {
            // then just give me this week's instance of that day
            return moment().add(1, 'weeks').isoWeekday(dayIneed).format("YYYY-MM-DD");
        } else {
            // otherwise, give me *next week's* instance of that same day
            return moment().add(2, 'weeks').isoWeekday(dayIneed).format("YYYY-MM-DD");
        }
    }

    const validationSchema =
        Yup.object().shape({
            products: Yup.array().min(1),
            deliveryAddress: Yup.string().required("Laukas privalomas").max(200, "Adresas negali viršyti 200 simbolių"),
            deliveryLocation: Yup.string().required("Laukas privalomas").max(50, "Pavadinimas negali viršyti 50 simbolių"),
            deliveryDate: Yup.string().required("Laukas privalomas").max(30, "Pristatymo data negali viršyti 30 simbolių"),
            name: Yup.string().required("Laukas privalomas").max(200, "Pavadinimas negali viršyti 200 simbolių"),
            phone: Yup.string().required("Laukas privalomas").max(30, "Tel.nr. data negali viršyti 30 simbolių"),
            email: Yup.string().required("Laukas privalomas").max(100, "El.pašto adresas negali viršyti 100 simbolių"),
            paymentType: Yup.string().required("Laukas privalomas").max(30, "Apmokėjimo būdas negali viršyti 30 simbolių"),
            additionalInfo: Yup.string().max(300, "Žinutė negali viršyti 300 simbolių")
        })

    const handleTabValueChange = (e, newValue) => {
        window.scrollTo(0, 0)
        setTabValue(newValue)
    }

    return (
        <>
            <div style={tabValue === "valgauSavo" ? {
                backgroundImage: `url(/assets/images/image0.jpeg)`,
                height: "100vh",
                // backgroundRepeat: 'no-repeat',
                width: '100%',
                backgroundSize: "cover",
                backgroundPosition: "center"
            } : {}}
            >

                <Formik
                    initialValues={{
                        products: [],
                        deliveryAddress: "",
                        deliveryLocation: "",
                        deliveryDate: "",
                        name: "",
                        phone: "",
                        email: "",
                        paymentType: "",
                        additionalInfo: ""
                    }}
                    validationSchema={() => validationSchema}
                    onSubmit={(values, { setSubmitting }) => {

                        const orderPrice = values.products.map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0)

                        axios.post("https://valgausavo-db098e5ac2e3.herokuapp.com/orders", { ...values, orderPrice }).then((resp) => {
                            // setSubmitting(false);
                            NotificationManager.success(`Dėkojame! Užsakymas sėkmingai pateiktas! Užsakymo gavimo patvirtinimą gausite el.paštu: ${resp.data}. (Jeigu mūsų el.laiško nerandate tarp gautųjų laiškų, pasitikrinkite savo "spam'ą" :)`, undefined, 8000);
                            setTimeout(() => {
                                window.location.reload()
                            }, 7000)

                        }).catch((err) => {
                            console.log("err", err)
                            setSubmitting(false)
                            NotificationManager.error(`Formuojant užsakymą įvyko klaida: ${err.response.data.message ? `${err.response.data.message.message} (${products.find((p)=> p.id === err.response.data.message.product).title})` : "Klaida"}`,err.response.data.message ? `${err.response.data.message.error}`: "Klaida" , 10000);
                        })
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValidating
                        /* and other goodies */
                    }) => (
                        <>
                            {/* <pre>cart: {JSON.stringify(cart, null, 2)}</pre> */}
                            {/* <pre>products: {JSON.stringify(products, null, 2)}</pre> */}
                            {/* <pre>values: {JSON.stringify(values, null, 2)}</pre> */}
                            {/* <pre>{JSON.stringify(touched, null, 2)}</pre> */}
                            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}

                            <Box className="sticky-top" style={{
                                zIndex: "99999"
                            }}>

                                <Tabs

                                    value={tabValue}
                                    onChange={handleTabValueChange}
                                    // textColor="secondary"
                                    // indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                    variant="fullWidth"
                                >
                                    <Tooltip title="Pagrindinis">
                                        <Tab value="valgauSavo"
                                            label="Pagrindinis"
                                            className={`${tabValue === "valgauSavo" ? "bg-dark-subtle " : "bg-secondary-subtle"} py-4`}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Užsisakyk">
                                        <Tab value="order"
                                            className={`${tabValue === "order" ? "bg-dark-subtle " : "bg-secondary-subtle"} py-4`}
                                            icon={
                                                <Badge badgeContent={`${values.products.length}`} color="success">
                                                    <ShoppingCartCheckoutIcon fontSize="large" className={`${tabValue === "order" ? "bg-dark-subtle " : "bg-secondary-subtle"}`} />
                                                </Badge>
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title="Krepšelis">
                                        <Tab value="cart" className={`${tabValue === "cart" ? "bg-dark-subtle" : "bg-secondary-subtle"} py-4`} icon={
                                            <Badge badgeContent={`${values.products.map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0).toFixed(2)}€`} color="success">
                                                <ShoppingBasketIcon fontSize="large" className={`${tabValue === "cart" ? "bg-dark-subtle" : "bg-secondary-subtle"} `} />
                                            </Badge>
                                        } />
                                    </Tooltip>

                                </Tabs>

                            </Box>

                            <Form onSubmit={handleSubmit}>
                                
                                <FieldArray
                                    name="products"
                                    render={arrayHelpers => (
                                        <>
                                            {tabValue === "order" &&
                                                (
                                                 <>
                                                    { productsDownloaded ? (
                                                    <>
                                                      {minOrderPrice !== undefined && (
                                                                <div className="">
                                                                    <Typography sx={{ color: 'text.secondary', fontSize: "1.0rem" }} className="text-center mt-2"
                                                                    >
                                                                        Šiuo metu Jums galime pasiūlyti užsisakyti šių produktų ( Minimali užsakymo suma {minOrderPrice.toFixed(2)} € ):
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        <div className="row container mx-auto">
                                                            {products.length ? products.map((product, index) => (
                                                                <>
                                                                    {
                                                                        product.farm === "Valgau Savo" ? (
                                                                            <ProductCard
                                                                                key={index}
                                                                                product={product}
                                                                                arrayHelpers={arrayHelpers}
                                                                                index={index}
                                                                                values={values}
                                                                            />
                                                                        ) : (false)
                                                                    }

                                                                </>
                                                            )) :
                                                            (
                                                                <Typography sx={{ color: 'text.secondary', fontSize: "1.5rem" }} className="text-center mt-2"
                                                                >
                                                                    Atsiprašome, šiuo metu neturime galimybės Jums pasiūlyti užsisakyti produktų :)
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        {
                                                            products.filter((p) => p.farm !== "Valgau Savo").length > 0 ? (
                                                                <div className="row container mx-auto">
                                                                    <div className="col-12 p-2">
                                                                        <Typography sx={{ color: 'text.secondary', fontSize: "1.0rem" }} className="text-center mt-2"
                                                                        >
                                                                            Taip pat siūlome įsigyti mūsų draugų ūkių produkciją ( Šie produktai neįsiskaičiuoja į minimalią krepšelio kainą ):
                                                                        </Typography>
                                                                    </div>
                                                                    {
                                                                        products.map((product, index) => (
                                                                            <>
                                                                                {
                                                                                    product.farm !== "Valgau Savo" ? (
                                                                                        <ProductCard
                                                                                            key={index}
                                                                                            product={product}
                                                                                            arrayHelpers={arrayHelpers}
                                                                                            index={index}
                                                                                            values={values}
                                                                                        />
                                                                                    ) : (false)
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : ""
                                                        }
                                                        {
                                                            values.products.filter((p) => p.unit === "vnt").length ? (
                                                                <Typography variant="body2" className="text-body-tertiary m-2 mb-3 container mx-auto">
                                                                    <span className="text-info">*</span> Ši kaina yra preliminari nes produkto svoris gali kisti +/- 20%. Paruošus užsakymą paskaičiuosime tikslią kainą.  
                                                                </Typography>
                                                            ): ""
                                                        }
                                                        </>
                                                    ):("")}
                                                    </>
                                                )
                                            }
                                            {tabValue === "cart" && (


                                                <>
                                                    {values.products.length === 0 ? (
                                                        <Table className="container">
                                                            <TableRow>
                                                                <TableCell align="center">
                                                                    <Typography sx={{ color: 'text.secondary', fontSize: "1.5rem" }}
                                                                    >
                                                                        Jūsų krepšelis tuščias
                                                                    </Typography>

                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    ) : (

                                                        <>
                                                            <TableContainer component={Paper} className="mt-2 mb-3 container">
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell
                                                                                align="center"
                                                                                colSpan={3}
                                                                                className="px-1"
                                                                            >

                                                                                <Typography className="text-success"
                                                                                >
                                                                                    Krepšelis
                                                                                </Typography>

                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                <Typography className="fw-bolder">
                                                                                    Aprašymas
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="center" className="px-1">
                                                                                <Typography className="fw-bolder">
                                                                                    Kiekis
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="right" className="px-1">
                                                                                <Typography className="fw-bolder">
                                                                                    Suma
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            values.products.filter((p) => p.farm === "Valgau Savo").length > 0 && (
                                                                                <>
                                                                                    {values.products.map((product, index) => (
                                                                                        <>
                                                                                            {
                                                                                                product.farm === "Valgau Savo" ? (
                                                                                                    <TableRow key={index}>
                                                                                                        <TableCell className="px-0">{product.title}
                                                                                                            <Typography sx={{ color: 'text.secondary', fontSize: "0.8rem" }}
                                                                                                            >
                                                                                                                (Kaina: {products.find((p) => p.id === product.id).price} € /{products.find((p) => p.id === product.id).perUnit})
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                        <TableCell align="center" className="px-0 d-flex justify-content-center align-items-center">
                                                                                                          
                                                                                                                <ReduceQuantityFab
                                                                                                                    values={values}
                                                                                                                    product={products.find((p) => p.id === product.id)}
                                                                                                                    productCartIndex={index}
                                                                                                                    arrayHelpers={arrayHelpers}
                                                                                                                />
                                                                                                                <Box sx={{ width: 100 }}>
                                                                                                                <QuantityChip
                                                                                                                    product={products.find((p) => p.id === product.id)}
                                                                                                                    values={values}
                                                                                                                    productCartIndex={index}
                                                                                                                />
                                                                                                                </Box>
                                                                                                                <IncreaseQuantityFab
                                                                                                                    values={values}
                                                                                                                    product={products.find((p) => p.id === product.id)}
                                                                                                                    productCartIndex={index}
                                                                                                                    arrayHelpers={arrayHelpers}
                                                                                                                />
                                                                                                        
                                                                                                        </TableCell>
                                                                                                        <TableCell align="right" className="px-0 text-body-tertiary">{product.price} € {product.unit === "vnt" ? (<span className='text-info'>*</span>) : ""}</TableCell>
                                                                                                    </TableRow>
                                                                                                ) : (false)
                                                                                            }

                                                                                        </>
                                                                                    ))}
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={2}>
                                                                                            <Typography className="text-bold text-black">
                                                                                                Suma:
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right" className="px-1">
                                                                                            <Typography className="text-bold text-black">
                                                                                                {values.products.filter((p) => p.farm === "Valgau Savo").map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0).toFixed(2)} € {values.products.filter((p) => p.farm === "Valgau Savo").map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0) < minOrderPrice ? (<span className='text-danger'>*</span>) : ""} {values.products.filter((p) => p.unit === "vnt" && p.farm === "Valgau Savo").length ? (<span className='text-info'>*</span>) : ""}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            values.products.filter((p) => p.farm !== "Valgau Savo").length > 0 ? (
                                                                                <>
                                                                                    {values.products.map((product, index) => (
                                                                                        <>
                                                                                            {
                                                                                                product.farm !== "Valgau Savo" ? (
                                                                                                    <TableRow key={index}>
                                                                                                        <TableCell className="px-0">{product.title}
                                                                                                            <Typography sx={{ color: 'text.secondary', fontSize: "0.8rem" }}
                                                                                                            >
                                                                                                                (Kaina: {products.find((p) => p.id === product.id).price} € /{products.find((p) => p.id === product.id).perUnit})
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                        <TableCell align="center" className="px-0">

                                                                                                            <ReduceQuantityFab
                                                                                                                values={values}
                                                                                                                product={products.find((p) => p.id === product.id)}
                                                                                                                productCartIndex={index}
                                                                                                                arrayHelpers={arrayHelpers}
                                                                                                            />
                                                                                                            <QuantityChip
                                                                                                                product={products.find((p) => p.id === product.id)}
                                                                                                                values={values}
                                                                                                                productCartIndex={index}
                                                                                                            />
                                                                                                            <IncreaseQuantityFab
                                                                                                                values={values}
                                                                                                                product={products.find((p) => p.id === product.id)}
                                                                                                                productCartIndex={index}
                                                                                                                arrayHelpers={arrayHelpers}
                                                                                                            />
                                                                                                        </TableCell>
                                                                                                        <TableCell align="right" className="px-0 text-body-tertiary">{product.price} €</TableCell>
                                                                                                    </TableRow>
                                                                                                ) : (false)
                                                                                            }

                                                                                        </>
                                                                                    ))}
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={2}>
                                                                                            <Typography className="text-bold text-black">
                                                                                                Suma:
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right" className="px-1">
                                                                                            <Typography className="text-bold text-black">
                                                                                                {values.products.filter((p) => p.farm !== "Valgau Savo").map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0).toFixed(2)} € {values.products.filter((p) => p.unit === "vnt" && p.farm !== "Valgau Savo").length ? (<span className='text-info'>*</span>) : ""}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </>
                                                                            ) : ""
                                                                        }

                                                                        <TableRow>
                                                                            <TableCell colSpan={2}>
                                                                                <Typography className="fw-bold text-black">
                                                                                    Viso suma:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="right" className="px-1">
                                                                                <Typography className="fw-bold text-black">
                                                                                    {values.products.map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0).toFixed(2)} € {values.products.filter((p) => p.unit === "vnt").length ? (<span className='text-info'>*</span>) : ""}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            {
                                                                values.products.filter((p) => p.farm === "Valgau Savo").map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0) < minOrderPrice && (
                                                                    <Typography variant="body2" className="text-body-tertiary m-2 mb-3 container mx-auto">
                                                                        <span className="text-danger">*</span> Ši suma turi būti ne mažesnė kaip {minOrderPrice} €
                                                                    </Typography>
                                                                )
                                                            }
                                                    
                                                            {
                                                                values.products.filter((p) => p.unit === "vnt").length && (
                                                                    <Typography variant="body2" className="text-body-tertiary m-2 mb-3 container mx-auto">
                                                                        <span className="text-info">*</span> Ši suma yra preliminari nes produkto svoris gali kisti +/- 20%. Paruošus užsakymą paskaičiuosime tikslią sumą.  
                                                                    </Typography>
                                                                )
                                                            }

                                                            <TableContainer component={Paper} className="mb-3 container">
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="center" colSpan={2} className="px-1">

                                                                                <Typography className="text-success"
                                                                                >
                                                                                    Pristatymo informacija
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">Pristatymas į:</TableCell>
                                                                            <TableCell className="px-1" align="left">
                                                                                <Field
                                                                                    name="deliveryLocation"
                                                                                    render={({ field /* { name, value, onChange, onBlur } */ }) => (
                                                                                        <>
                                                                                            <RadioGroup
                                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                                name="deliveryLocation"
                                                                                                value={values.deliveryLocation}
                                                                                                onChange={() => setFieldValue("deliveryDate", undefined)}
                                                                                            >
                                                                                                <FormControlLabel {...field} value="Elektrėnai" control={<Radio color="success" />} label="Elektrėnus" />
                                                                                                {/* <FormControlLabel {...field} value="Vilnius" control={<Radio color="success" />} label="Vilnių" /> */}
                                                                                            </RadioGroup>
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                                {touched.deliveryLocation && errors.deliveryLocation && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.deliveryLocation}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                Pristatymo data:
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                {values.deliveryLocation !== "" ? (
                                                                                    <Field name="deliveryDate">
                                                                                        {() => (
                                                                                            <FormControl fullWidth>
                                                                                                <InputLabel id="demo-simple-select-label">Pristatymo data:</InputLabel>
                                                                                                {values.deliveryLocation === "Elektrėnai" && (
                                                                                                    <Select
                                                                                                        color="success"
                                                                                                        name="deliveryDate"
                                                                                                        labelId="demo-simple-select-label"
                                                                                                        id="demo-simple-select"
                                                                                                        value={values.deliveryDate}
                                                                                                        label="Pasirinkite pristatymo datą"
                                                                                                        onChange={handleChange}
                                                                                                    >
                                                                                                        <MenuItem value={moment().add(1, "days").format("YYYY-MM-DD")}>{moment().add(1, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                        <MenuItem value={moment().add(2, "days").format("YYYY-MM-DD")}>{moment().add(2, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                        <MenuItem value={moment().add(3, "days").format("YYYY-MM-DD")}>{moment().add(3, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                        <MenuItem value={moment().add(4, "days").format("YYYY-MM-DD")}>{moment().add(4, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                        <MenuItem value={moment().add(5, "days").format("YYYY-MM-DD")}>{moment().add(5, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                        <MenuItem value={moment().add(6, "days").format("YYYY-MM-DD")}>{moment().add(6, "days").format("YYYY-MM-DD")}</MenuItem>
                                                                                                    </Select>
                                                                                                )}
                                                                                                {values.deliveryLocation === "Vilnius" && (
                                                                                                    <Select
                                                                                                        name="deliveryDate"
                                                                                                        labelId="demo-simple-select-label"
                                                                                                        id="demo-simple-select"
                                                                                                        value={values.deliveryDate}
                                                                                                        label="Pasirinkite pristatymo datą"
                                                                                                        onChange={handleChange}
                                                                                                    >
                                                                                                        <MenuItem value={calculateNextMonday()}>{calculateNextMonday()}</MenuItem>
                                                                                                    </Select>
                                                                                                )}
                                                                                            </FormControl>
                                                                                        )}
                                                                                    </Field>
                                                                                ) :

                                                                                    ("Pasirinkite pristatymo vietą")}
                                                                                {touched.deliveryDate && errors.deliveryDate && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.deliveryDate}
                                                                                    </Typography>
                                                                                )}

                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                Vardas, Pavardė
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                <TextField
                                                                                    color="success"
                                                                                    fullWidth
                                                                                    value={values.name}
                                                                                    name="name"
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {touched.deliveryAddress && errors.deliveryAddress && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.name}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                Pristatymo adresas:
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                <TextField
                                                                                    color="success"
                                                                                    fullWidth
                                                                                    value={values.deliveryAddress}
                                                                                    name="deliveryAddress"
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {touched.deliveryAddress && errors.deliveryAddress && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.deliveryAddress}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                Tel.nr:
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                <TextField
                                                                                    color="success"
                                                                                    fullWidth
                                                                                    value={values.phone}
                                                                                    name="phone"
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {touched.phone && errors.phone && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.phone}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                El.paštas:
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                <TextField
                                                                                    type="email"
                                                                                    color="success"
                                                                                    fullWidth
                                                                                    value={values.email}
                                                                                    name="email"
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {touched.email && errors.email && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.email}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">Apmokėjimas:</TableCell>
                                                                            <TableCell className="px-1" align="left">
                                                                                <Field
                                                                                    name="paymentType"
                                                                                    render={({ field }) => (
                                                                                        <>
                                                                                            <RadioGroup
                                                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                                                name="paymentType"
                                                                                                value={values.paymentType}
                                                                                            // onChange={() => setFieldValue("deliveryDate", undefined)}
                                                                                            >
                                                                                                <FormControlLabel {...field} value="bankTransfer" control={<Radio color="success" />} label="Bankiniu pavedimu" />
                                                                                                <FormControlLabel {...field} value="cash" control={<Radio color="success" />} label="Grynaisiais" />
                                                                                            </RadioGroup>
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                                {touched.paymentType && errors.paymentType && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.paymentType}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="px-1">
                                                                                Papildoma informacija:
                                                                            </TableCell>
                                                                            <TableCell className="px-1">
                                                                                <Typography sx={{ color: 'text.secondary', fontSize: ".7rem" }} 
                                                                                >
                                                                                    {values.additionalInfo.length} / 300
                                                                                </Typography>
                                                                                <TextField
                                                                                    color="success"
                                                                                    fullWidth
                                                                                    value={values.additionalInfo}
                                                                                    name="additionalInfo"
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {touched.additionalInfo && errors.additionalInfo && (
                                                                                    <Typography className="text-danger"
                                                                                    >
                                                                                        {errors.additionalInfo}
                                                                                    </Typography>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <div className="container px-0">
                                                                {isSubmitting ? (
                                                                    <LoadingButton loading variant="outlined" color="success" fullWidth className="mb-3">
                                                                        Pateikti užsakymą
                                                                    </LoadingButton>
                                                                ) : (
                                                                    <Button variant="contained" color="success" type="submit" fullWidth className="mb-3" disabled={values.products.filter((p) => p.farm === "Valgau Savo").map((prod) => parseFloat(prod.price)).reduce((a, b) => a + b, 0) < minOrderPrice}>
                                                                        Pateikti užsakymą
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                            }
                                        </>
                                    )}
                                />
                            </Form>

                        </>
                    )}
                </Formik>
                {tabValue === "valgauSavo" && (
                    <>
                        <div className="d-none d-md-block">
                            <div className="container">

                                <img src={`/assets/images/Logo(be fono)-baltas.svg`} alt="Valgau Savo" height="200" />
                            </div>
                            <div className="container text-white">
                                <Typography variant="h1" className="" style={{ extShadow: "1px 1px #ced4da" }}>
                                    Aplinkai
                                </Typography>
                                <Typography variant="h1" className=""
                                    style={{ textShadow: "1px 1px #ced4da" }}>
                                    palankus
                                </Typography>
                                <Typography variant="h1" className=""
                                    style={{ textShadow: "1px 1px #ced4da" }}>
                                    ūkis
                                </Typography>
                            </div>
                        </div>
                        <div className="d-md-none">
                            <div className="container d-flex justify-content-center">

                                <img src={`/assets/images/Logo(be fono)-baltas.svg`} alt="Valgau Savo" height="150" />
                            </div>
                            <div className="container text-white">
                                <Typography variant="h2" className="" style={{ extShadow: "1px 1px #ced4da" }}>
                                    Aplinkai
                                </Typography>
                                <Typography variant="h2" className=""
                                    style={{ textShadow: "1px 1px #ced4da" }}>
                                    palankus
                                </Typography>
                                <Typography variant="h2" className=""
                                    style={{ textShadow: "1px 1px #ced4da" }}>
                                    ūkis
                                </Typography>
                            </div>
                        </div>
                    </>
                )
                }
                <Snackbar
                    open={openSnackBar}
                    onClose={handleClose}
                    autoHideDuration={2500}
                >
                    <Alert
                        onClose={handleClose}
                        severity="info"
                        sx={{ width: '100%' }}
                    >
                        Apgailestaujame, tačiau šiuo metu didedsnio kiekio vienam užsakymui pasiūlyti negalime :)
                    </Alert>

                </Snackbar>
                <NotificationContainer />
            </div>
            {tabValue === "valgauSavo" && (
                <>
                    <div className="bg-dark-subtle">
                        <div className="container py-5">
                            <Typography className="lh-base">
                                "Valgau Savo" gimė iš paprasto, bet labai svarbaus troškimo – turėti šviežio, įvairaus ir natūraliai užauginto maisto. Tai nedidelis šeimos ūkis Elektrėnų savivaldybėje, kuriame rūpinamės savo poreikiais, o tai, ko užauginame daugiau nei patys sunaudojame, siūlome įsigyti ir jums.
                            </Typography>
                            <Typography className="mt-3 lh-base">
                                Taip gimė dar viena idėja - ši platforma, kurioje galite peržiūrėti sezoninius produktus, kuriuos galite įsigyti iš mūsų ūkio ir susikurti savo daržovių krepšelį elektroniniu būdu, patys nuspręsdami, kokių ir kiek produktų norite.
                            </Typography>
                            <Typography className="mt-3 lh-base">
                                Čia galite matyti, kokius produktus turime pasiūlyti, jų kainas, ir pateikti užsakymą tiesiogiai. Ši platforma taupo tiek jūsų, tiek mūsų laiką – jums nebereikia individualiai teirautis apie turimus produktus, o mes galime efektyviau valdyti užsakymus.
                            </Typography>
                            <Typography className="mt-3 lh-base">
                                Norime pabrėžti, kad mūsų ūkis yra labai mažas, todėl turime ribotą kiekį produkcijos. Pristatymus vykdome Elektrėnų teritorijoje, o kartais galime atvežti ir į Vilnių arba Kauną :)
                            </Typography>
                        </div>
                    </div>
                    <div className="bg-dark p-3 text-center d-flex justify-content-around align-items-center flex-column flex-md-row">
                        <div className="my-2">
                            <Link href="https://www.instagram.com/valgau_savo" target="_blank" underline="none" className="text-white">
                                <InstagramIcon fontSize="large" />
                            </Link>
                            <Link href="https://www.facebook.com/people/Valgau-Savo/100095014186716" target="_blank" underline="none" className="text-white">
                                <FacebookIcon fontSize="large" />
                            </Link>
                        </div>
                        <Typography color="text.secondary" className="text-white my-2">
                            <CallIcon /> +370 601 14187
                        </Typography>
                        <Typography color="text.secondary" className="text-white my-2">
                            <LocationOnIcon /> Pagrenda 6A, Pastrėvio sen., Elektrėnų sav.
                        </Typography>
                    </div>
                </>
            )}

        </>
    );
}

export default App;
